import { SuperHeroProps } from "@bluebottlecoffee/design-system";
import { Hero as Schema } from "../sanity-schema";
import { toHeroProps } from "./hero";

export function toSuperHeroProps (
    data:Schema[], 
    region:string,
    lang:string
):SuperHeroProps {
    return { heroes: data.map(h => toHeroProps(h, region, lang)) }
}